<template>
    <v-dialog
        v-model="toogleShowModal"
        max-width="100mm"
        persistent
    >
        <v-card>
            <v-card-text>
                <div
                    id="ticket"
                    class="ticket"
                >
                    <img
                        v-if="user.company.logo"
                        style="margin-top: 15px"
                        class="profile mx-auto d-block"
                        :src="user.company.logo"
                        alt="LOGO"
                    >
                    <p class="centrado">
                        {{ $vuetify.lang.t("$vuetify.sale.ticket_buy") }}<br>
                        <b><i>{{ editBuy.shop.name }}</i></b><br>
                        <b>{{ $vuetify.lang.t("$vuetify.tax.noFacture") }}</b>: {{ editBuy.no_facture }}<br>
                        <b>{{ $vuetify.lang.t('$vuetify.menu.coin') + ': ' }}</b>
                        {{ user.company.currency }}<br>
                        {{ new Date(editBuy.updated_at).toUTCString() }}<br>
                        {{
                            user.company.slogan
                                ? user.company.slogan.toUpperCase()
                                : ""
                        }}
                    </p>
                    <div style="width: 100%; margin-bottom: 20px">
                        <v-col
                            style="width: 100%;padding: inherit"
                            cols="12"
                            md="12"
                        >
                            <table style="width: 100%; border-bottom-style: double;border-top-style:double">
                                <tr>
                                    <th class="cantidad">
                                        {{ $vuetify.lang.t('$vuetify.report.cant').toUpperCase() }}
                                    </th>
                                    <th class="producto">
                                        {{ $vuetify.lang.t('$vuetify.menu.article').toUpperCase() }}
                                    </th>
                                    <th class="precio">
                                        $$
                                    </th>
                                </tr>
                            </table>
                            <p style="margin-top: 15px; text-align: center;margin-bottom: 10px;">
                                ***********************************
                            </p>
                        </v-col>
                        <v-col
                            v-for="(sale_article_shop,i) in editBuy.sale_article_shops"
                            :key="i"
                            style="width: 100%;padding: inherit"
                            cols="12"
                            md="12"
                        >
                            <table style="width: 100%; border-bottom-style: dotted; margin-bottom: 15px">
                                <tbody>
                                <tr>
                                    <td class="cantidad" width="30px">
                                        {{ parseFloat(sale_article_shop.cant).toFixed(2) }} {{ getUM(sale_article_shop) }}
                                    </td>
                                    <td class="producto">{{ sale_article_shop.article_shops.article.nameComplete }}
                                      <template v-if="sale_article_shop.article_shops.article.variant_properties.length > 0">
                                        <p style="margin-bottom: 0px"><b>{{$vuetify.lang.t('$vuetify.menu.detail')}}:</b></p>
                                        <p v-for="(property, i) in sale_article_shop.article_shops.article.variant_properties" :key="i" style="margin-bottom: 0px"><b>{{ property.variant }}:</b> {{ property.property }}</p>
                                      </template>
                                        </td>
                                    <td class="precio">
                                        {{
                                            `${user.company.currency + ' ' + parseFloat(sale_article_shop.totalCost).toFixed(2)}`
                                        }}
                                    </td>
                                </tr>
                                <template v-if="sale_article_shop.discounts.length >0">
                                    <tr>
                                        <td
                                            colspan="3"
                                            style="width: 100%"
                                        >
                                            <table
                                                style="width: 100%"
                                            >
                                                <tbody>
                                                <tr
                                                    v-for="(lDiscount, j) of sale_article_shop.discounts"
                                                    :key="j"
                                                >
                                                    <td>
                                                        {{ $vuetify.lang.t('$vuetify.menu.discount') }}
                                                    </td>
                                                    <td>
                                                        {{ lDiscount.name }}{{ lDiscount.percent ? '('+lDiscount.value +'%) ':' ' }}
                                                    </td>
                                                    <td style="text-align: center">
                                                        <i> -{{ lDiscount.percent ? parseFloat(lDiscount.value*art.cant*art.cost/100).toFixed(2) : parseFloat(lDiscount.value).toFixed(2) }}
                                                        </i>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </template>
                                <!--template  v-if="sale_article_shop.article_shops.article.taxes.length >0">
                                    <tr>
                                        <td
                                            colspan="3"
                                            style="width: 100%"
                                        >
                                            <table
                                                style="width: 100%"
                                            >
                                                <tbody>
                                                <tr
                                                    v-for="(tax, l) of sale_article_shop.article_shops.article.taxes"
                                                    :key="l"
                                                >
                                                    <td>
                                                        {{ $vuetify.lang.t('$vuetify.articles.tax') }}
                                                    </td>
                                                    <td>
                                                        {{ tax.name }}{{ tax.percent ? '('+tax.value +'%) ':' ' }}
                                                    </td>
                                                    <td style="text-align: center">
                                                        <i> + {{ user.company.currency }} {{ tax.percent ? parseFloat(sale_article_shop.cant* sale_article_shop.article_shops.article.cost* tax.value /100).toFixed(2) : parseFloat(tax.value).toFixed(2) }}
                                                        </i>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </template-->
                                <tr>
                                    <td colspan="2">{{ $vuetify.lang.t('$vuetify.pay.total').toUpperCase() }}</td>
                                    <td> <b>{{user.company.currency + ' ' + parseFloat(sale_article_shop.totalCost).toFixed(2) }}</b></td>
                                </tr>
                                </tbody>
                            </table>
                        </v-col>
                    </div>
                    <b style="margin-top: 10px">{{ $vuetify.lang.t('$vuetify.report.breakdown').toUpperCase() }}</b>
                    <table style="width: 100%; margin-bottom: 10px">
                        <tbody>
                        <tr>
                            <td><b>{{ $vuetify.lang.t('$vuetify.pay.sub_total') }}</b></td>
                            <td style="text-align: right">
                                {{user.company.currency + ' ' + parseFloat(localSubTotal).toFixed(2) }}
                            </td>
                        </tr>
                        <!--template v-if="localTaxesArticle.length !== 0">
                            <tr
                                v-for="(tax, k) in localTaxesArticle"
                                :key="k"
                            >
                                <td><b>{{ $vuetify.lang.t('$vuetify.articles.tax') }}({{ tax.name }})</b></td>
                                <td
                                    style="text-align: right"
                                >
                                    <i>{{user.company.currency + ' ' + tax.total }}
                                    </i>
                                </td>
                            </tr>
                        </template-->
                        <template v-if="editBuy.taxes.length !== 0">
                            <tr
                                v-for="(tax, k) in editBuy.taxes"
                                :key="k"
                            >
                                <td><b>{{ $vuetify.lang.t('$vuetify.articles.tax') }}({{ tax.name }})</b></td>
                                <td
                                    v-if="tax.percent==='true'"
                                    style="text-align: right"
                                >
                                    <i>{{ user.company.currency + ' ' + parseFloat(tax.value * sub_total / 100).toFixed(2) }} ({{ tax.value }}%)
                                    </i>
                                </td>
                                <td
                                    v-else
                                    style="text-align: right"
                                >
                                    <i>{{ user.company.currency + ' ' + tax.value }}</i>
                                </td>
                            </tr>
                        </template>
                        <template>
                            <tr
                                v-for="disc in editBuy.discounts"
                                :key="disc.id"
                            >
                                <td><b>{{ disc.name }}{{ disc.percent ? '('+disc.value +'%)':'' }}</b></td>
                                <td
                                    v-if="disc.percent==='true'"
                                    style="text-align: right"
                                >
                                    <i>-{{ parseFloat(disc.value * sub_total / 100).toFixed(2) }}</i>
                                </td>
                                <td
                                    v-else
                                    style="text-align: right"
                                >
                                    <i>{{ disc.value }}</i>
                                </td>
                            </tr>
                        </template>
                        <tr>
                            <td>
                                <p style="text-transform: uppercase;">
                                    <b>{{ $vuetify.lang.t('$vuetify.pay.total') }}: </b>
                                </p>
                            </td>
                            <td style="text-align: right;font-size: large;text-decoration: underline;}">
                                {{ `${user.company.currency + ' ' + parseFloat(editBuy.totalCost).toFixed(2)}` }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <b style="margin-top: 10px">{{ $vuetify.lang.t('$vuetify.report.breakdown').toUpperCase() }}</b>
                    <table style="width: 100%">
                        <tbody>
                        <tr
                            v-for="(pay, h) in editBuy.payments"
                            :key="h"
                            class="total"
                        >
                            <td
                                class="price"
                                style="border-right: black"
                            >
                                {{ $vuetify.lang.t('$vuetify.payment.' + pay.bank_payment.method) }}:
                            </td>
                            <td
                                class="price"
                                style="text-align: right"
                            >
                                {{ `${pay.currency ? pay.currency.currency : user.company.currency + ' ' + parseFloat(pay.cant).toFixed(2)}` }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table
                        v-if="editBuy.payments.filter(pay=>pay.bank_payment.method === 'cash').length > 0"
                        style="width: 100%"
                    >
                        <thead>
                        <tr>
                            <th class="cantidad">
                                {{ $vuetify.lang.t('$vuetify.payment.cant_pay').toUpperCase() }}
                            </th>
                            <th class="producto">
                                {{ $vuetify.lang.t('$vuetify.payment.cant_charge').toUpperCase() }}
                            </th>
                            <th class="precio">
                                {{ $vuetify.lang.t('$vuetify.payment.cant_back') }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(pay, h) in editBuy.payments.filter(pay=>pay.bank_payment.method === 'cash')"
                            :key="h"
                        >
                            <td
                                class="cantidad"
                                style="text-align: center"
                            >
                                {{ `${pay.bank_payment.method ==='cash'? pay.currency !==null ? pay.currency.currency : user.company.currency :user.company.currency }` }} {{ parseFloat(pay.cant_pay).toFixed(2) }}
                            </td>
                            <td
                                class="producto"
                                style="text-align: center"
                            >
                                <div>{{user.company.currency + ' ' + parseFloat(pay.cant).toFixed(2) }}</div>
                            </td>
                            <td
                                class="price"
                                style="text-align: center"
                            >
                                {{ `${pay.cant_back? user.company.currency:'' }` }} {{ parseFloat(pay.cant_back).toFixed(2) }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p class="centrado">
                        <i> {{ user.company.footer ? user.company.footer.toUpperCase(): '' }}</i><br>
                        {{ $vuetify.lang.t('$vuetify.report.contact_us') +': '+ user.company.email }}<br>
                      <b>{{editBuy.create ? $vuetify.lang.t("$vuetify.articles.sell_by") + ": " : ''}}</b>
                      {{editBuy.create ? editBuy.create.firstName : '' }} {{ editBuy.create ? editBuy.create.lastName ? editBuy.create.lastName : "" : '' }}<br>
                    </p>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    class="mb-2"
                    @click="cancelModal"
                >
                    <v-icon>mdi-close</v-icon>
                    {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                    class="mb-2"
                    color="primary"
                    @click="printFacture"
                >
                    <v-icon>mdi-printer</v-icon>
                    {{ $vuetify.lang.t('$vuetify.actions.print') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import printJS from 'print-js'

export default {
  name: 'PrintFacture',
  props: {
    id: {
      type: String,
      default: ''
    },
    go: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localSubTotal: 0,
      totalTax: 0,
      localTaxesArticle: [],
      totalDisc: 0,
      total: 0,
      sub_total: 0
    }
  },
  computed: {
    ...mapState('buy', ['editBuy']),
    ...mapState('discount', ['discounts']),
    ...mapGetters('auth', ['user', 'userPin'])
  },
  async mounted () {
    await this.getDiscounts()
    this.totalTax = 0
    this.totalDisc = 0
    this.total = 0
    this.sub_total = 0
    this.editBuy.sale_article_shops.forEach(v => {
      v.article_shops.article.taxes.forEach(vT => {
        const tax = this.localTaxesArticle.filter(t => (t.id === vT.id))

        if (tax.length > 0) {
          const t = tax[0]
          t.total = parseFloat(parseFloat(vT.percent ? (v.cant * v.article_shops.article.cost * vT.value / 100) : vT.value) + parseFloat(t.total)).toFixed(2)
        } else {
          const t = {
            name: vT.name,
            id: vT.id,
            total: vT.percent ? parseFloat(v.cant * v.article_shops.article.cost * vT.value / 100).toFixed(2) : parseFloat(vT.value).toFixed(2)
          }
          this.localTaxesArticle.push(t)
        }
      })
      this.localSubTotal = parseFloat(v.totalCost) + this.localSubTotal
      this.sub_total = parseFloat(v.totalCost) + this.sub_total
    })
    this.editBuy.taxes.forEach(v => {
      this.totalTax += v.totalTax
    })
    this.editBuy.discounts.forEach(v => {
      this.totalDisc += v.totalDiscount
    })
    this.total = (this.sub_total + parseFloat(this.totalTax) - parseFloat(this.totalDisc)).toFixed(2)
    this.total = parseFloat(this.total).toFixed(2)
    this.$emit('updateData')
  },
  methods: {
    ...mapActions('buy', ['toogleShowModal']),
    ...mapActions('discount', ['getDiscounts']),
    // eslint-disable-next-line camelcase
    getUM (sale_article_shop) {
      let um = ''
      if (sale_article_shop.um) {
        um = sale_article_shop.um.name
      }
      return um
    },
    total_pay (item) {
      let sum = 0
      item.taxes.forEach(v => {
        sum += v.percent
          ? (item.cant * item.cost * v.value) / 100
          : v.value
      })
      let discount = 0
      item.discount.forEach(v => {
        discount += v.percent
          ? (item.cant * item.cost * v.value) / 100
          : v.value
      })
      return item.cant * item.cost + sum - discount - item.moneyRefund
    },
    printFacture () {
      printJS({
        printable: 'ticket',
        type: 'html',
        // targetStyles: ['*'],
        style: '.profile { width: 100px; height: 100px; border-radius: 50%; }' +
                    '* { font-size: 12px; font-family: "DejaVu Sans", serif;}' +
                    ' .profile { width: 100px; height: 100px; border-radius: 50%; }' +
                    ' .centrado { text-align: center; align-content: center; }' +
                    ' td, th, tr, table { border-collapse: collapse; margin: 15 auto;}' +
                    'td.producto, th.producto { width: 80px; max-width: 80px; }' +
                    'td.cantidad, th.cantidad { margin-left: 0px; width: 40px; max-width: 40px; word-break: break-all;}' +
                    'td.precio, th.precio { width: 50px; max-width: 50px; word-break: break-all;}' +
                    '#ticket { padding-top: 5px; align-content: center; width: 75mm; max-width: 75mm;}' +
                    'img { max-width: inherit; width: inherit;}',
        scanStyles: false,
        header: '',
        onPrintDialogClose: () => {
          this.toogleShowModal(false)
          if (this.go) {
            this.$router.push({ name: 'vending' })
          }
        },
        onError: () => {
          this.toogleShowModal(false)
          if (this.go) {
            this.$router.push({ name: 'vending' })
          }
        }
      })
    },
    cancelModal () {
      this.toogleShowModal(false)
      if (this.go) {
        this.$router.push({ name: 'vending' })
      }
    }
  }
}
</script>

<style scoped>
.profile {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
* {
    font-size: 12px;
    font-family: 'DejaVu Sans', serif;
}
td,
th,
tr,
table {
    border-collapse: collapse;
    margin: 15 auto;
}

td.producto,
th.producto {
    width: 80px;
    max-width: 80px;
}

td.cantidad,
th.cantidad {
    margin-left: 0px;
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

td.precio,
th.precio {
    width: 50px;
    max-width: 50px;
    word-break: break-all;
}

.centrado {
    text-align: center;
    align-content: center;
}

#ticket {
    padding-top: 5px;
    align-content: center;
    width: 75mm;
    max-width: 75mm;
}

img {
    max-width: inherit;
    width: inherit;
}
@media print {
    @page { margin: 0; }
    body { margin: 1.6cm; }
    .profile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    * {
        font-size: 12px;
        font-family: 'DejaVu Sans', serif;
    }
    td,
    th,
    tr,
    table { border-collapse: collapse;
        margin: 15 auto;
    }
    td.producto,
    th.producto {
        width: 80px;
        max-width: 80px;
    }
    td.cantidad,
    th.cantidad {
        margin-left: 0px;
        width: 40px;
        max-width: 40px;
        word-break: break-all;
    }

    td.precio,
    th.precio {
        width: 50px;
        max-width: 50px;
        word-break: break-all;
    }

    .centrado {
        text-align: center;
        align-content: center;
    }

    #ticket {
        padding-top: 5px;
        align-content: center;
        width: 75mm;
        max-width: 75mm;
    }

    img {
        max-width: inherit;
        width: inherit;
    }
}

</style>
