<template>
  <v-card width="350">
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <b style="text-transform: uppercase">{{
              $vuetify.lang.t("$vuetify.cost")
            }}</b>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          {{
            `${parseFloat(article.subTotalCost).toFixed(2) + ' ' + getCurrency}`
          }}
        </v-col>
      </v-row>
      <v-row
        v-for="mod in article.modifiers"
        :key="mod.name"
      >
        <v-col
          cols="12"
          md="6"
        >
          <b style="color: darkblue">{{ $vuetify.lang.t("$vuetify.menu.modifiers") + ': ' + mod.name }}</b>
        </v-col>
        <v-col
          v-if="mod.percent === 'true'"
          cols="12"
          md="6"
        >
          <i
            style="color: darkblue"
          >+{{
              `${parseFloat((mod.cant * article.cost) / 100).toFixed(2) + ' ' + getCurrency}`
            }}
            ({{ mod.cant }}%)</i>
        </v-col>
        <v-col
          v-else
          cols="12"
          md="6"
        >
          <i
            style="color: darkblue"
          >+{{
              `${parseFloat(mod.cant).toFixed(2) + ' ' + getCurrency}`
            }}</i>
        </v-col>
      </v-row>
      <v-row
        v-for="disc in article.discounts"
        :key="disc.name"
      >
        <v-col
          cols="12"
          md="6"
        >
          <b style="color: red">{{ $vuetify.lang.t('$vuetify.menu.discount') + ': ' + disc.name }}</b>
        </v-col>
        <v-col
          v-if="disc.percent === 'true'"
          cols="12"
          md="6"
        >
          <i
            style="color: red"
          >-{{
              `${parseFloat((disc.cant * (article.subTotalCost + article.totalModifierCost)) / 100).toFixed(2) + ' ' + getCurrency}`
            }}</i>
        </v-col>
        <v-col
          v-else
          cols="12"
          md="6"
        >
          <i
            style="color: red"
          >-{{ `${disc.cant}` + ' ' + getCurrency }}</i>
        </v-col>
      </v-row>
      <v-divider v-if="article.article_shops.article.taxes.length > 0"/>
      <v-row v-if="article.article_shops.article.taxes.length > 0">
        <v-col md="6" cols="12"/>
        <v-col md="6" cols="12">
          <p class="align-content-end">{{parseFloat(article.subTotalCost + article.totalModifierCost - article.totalDiscountCost).toFixed(2) + ' ' + getCurrency}}</p>
        </v-col>
      </v-row>
      <v-row
        v-for="tax in article.article_shops.article.taxes"
        :key="tax.name"
      >
        <v-col
          cols="12"
          md="6"
        >
          <b
            style="color: darkblue"
          >{{ $vuetify.lang.t("$vuetify.tax.name") + ': ' + tax.name }})</b>
        </v-col>
        <v-col
          v-if="tax.percent === 'true'"
          cols="12"
          md="6"
        >
          <i
            style="color: darkblue"
          >+{{ `${parseFloat((tax.cant * (article.subTotalCost + article.totalModifierCost - article.totalDiscountCost)) / 100).toFixed(2)}` + ' ' + getCurrency }}
            ({{ tax.cant }}%)</i>
        </v-col>
        <v-col
          v-else
          cols="12"
          md="6"
        >
          <i style="color: darkblue">+{{ `${parseFloat(tax.cant).toFixed(2)}` + ' ' + getCurrency }}</i>
        </v-col>
      </v-row>
      <v-row v-if="article.totalRefund > 0">
        <v-col
          cols="12"
          md="6"
        >
          <b style="color: red">{{ $vuetify.lang.t("$vuetify.menu.refund") }}</b>
        </v-col>
        <v-col>
          <i style="color: red">-{{
              `${parseFloat(article.totalRefund).toFixed(2)}` + ' ' + getCurrency
            }}</i>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <b style="text-transform: uppercase">{{
              $vuetify.lang.t("$vuetify.pay.total")
            }}</b>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          {{
            `${parseFloat(article.totalCost).toFixed(2)}` + ' ' + getCurrency
          }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DetailArticleBuy',
  props: {
    article: {
      type: Object,
      default: function () {
        return {}
      }
    },
    currency: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    }
  },
  methods: {
    convertDate (dateString) {
      const date = new Date(dateString)

      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const hour = date.getHours()
      const minutes = date.getMinutes()
      const seconds = date.getSeconds()
      if (month < 10) {
        return (`${day}-0${month}-${year} ${hour}:${minutes}:${seconds}`)
      } else {
        return (`${day}-${month}-${year} ${hour}:${minutes}:${seconds}`)
      }
    }
  }
}
</script>

<style scoped></style>
